import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import logo from "../../assets/images/logo.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from 'react-toastify';


const ResetPasswordModal = ({ show, handleClose, handleShow, resetToken }) => {

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [password, setPassword] = useState('');

  const validatePassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const toastId = toast.loading('Logging in...', {
      toastId: "login_toast",
      autoClose: false,
    });

    if (password === '') {
      toast.update(toastId, {
        render: "Please fill in all fields.",
        type: "info",
        autoClose: 2000,
        isLoading: false
      });
      return;
    }

    if (!validatePassword(password)) {
      toast.update(toastId, {
        render: "Your password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character.",
        type: "info",
        autoClose: 2000,
        isLoading: false
      })
      return
    }

    try {
      const response = await fetch('https://islamic-world-1.onrender.com/api/users/reset-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password, "token": resetToken }),
      });

      const data = await response.json();

      if (response.ok) {
        setPassword('');

        toast.update(toastId, {
          render: data.message,
          type: "success",
          autoClose: 2000,
          isLoading: false
        });
        handleShow("login")

      } else {
        toast.update(toastId, {
          render: data.message,
          type: "error",
          autoClose: 2000,
          isLoading: false
        });
      }
    } catch (err) {
      toast.update(toastId, {
        render: err.message,
        type: "error",
        autoClose: 2000,
        isLoading: false
      });

    } finally {
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="md"
      className="modal-login-sec"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Row className="justify-content-center">
          <Col sm={12} md={9} lg={9}>
            <div className="logo">
              <img className="img-fluid" src={logo} alt="Logo" />
            </div>
            <div className="title">
              <h2>
                Reset <span>Password!</span>
              </h2>
            </div>
            <form onSubmit={handleSubmit}>
              <Row className="gy-4">
                <Col sm={12}>
                  <div className="txt-box pass">
                    <input
                      placeholder="Password"
                      type={showPassword ? 'text' : 'password'}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <i
                      className={`fa-regular ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}
                      onClick={togglePasswordVisibility}
                      style={{ cursor: 'pointer' }}
                    ></i>
                  </div>
                </Col>
                <Col sm={12}>
                  <div className="btn-sub create">
                    <button
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ResetPasswordModal;
