import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { ToastContainer, toast } from 'react-toastify';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated'
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import axios from "axios";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ReferralBox from "../component/ReferralBox";
import user from "../assets/images/user.png";
import invitePost from "../assets/images/invite-post.png";
import imgPost from "../assets/images/img-post.png";
import TransactionModal from "../component/modals/TransactionModal";

const LandingPage = () => {


  const isAuthenticated = useIsAuthenticated();
  const auth = useAuthUser();



  // slick slider 
  const [currentSlide, setCurrentSlide] = useState(0);
  const [content, setContent] = useState(null);

  const [file, setFile] = useState(null);
  const [postText, setPostText] = useState('');


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
  };

  // slick end 

  const handleClick = () => {
    toast.info("Please login to continue.", { autoClose: 2000, toastId: "uplod" })
  }

  const fetchContent = async () => {
    let contentUrl = ""; // Use let instead of const so that you can reassign the value

    if (isAuthenticated) {
      contentUrl = `https://islamic-world-1.onrender.com/api/users/get-content/${auth.user._id}`;

    } else {
      contentUrl = "https://islamic-world-1.onrender.com/api/web/get-content/";

    }
    try {
      const response = await axios.get(contentUrl,
        {
          headers: {
            'x-auth-token': isAuthenticated ? auth.user.token : "",
          },
        }
      );

      setContent(response.data.data);
    } catch (error) {
      toast.error('Error fetching content: ' + error.message)
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleTextChange = (e) => {
    setPostText(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const uploadToast = toast.loading("Uplaoding...", {
      toastId: 'upload_content',
      autoClose: false
    })

    if (!file) {
      toast.update(uploadToast, {
        render: 'Please upload a file.',
        autoClose: 2000,
        type: "info",
        isLoading: false
      });
      return;
    }


    // Determine the file type
    let fileType = '';
    const mimeType = file.type;

    if (mimeType.startsWith('image/')) {
      fileType = 'picture';
    } else if (mimeType.startsWith('video/')) {
      fileType = 'video';
    } else if (mimeType === 'application/pdf') {
      fileType = 'pdf';
    } else {
      toast.update(uploadToast, {
        render: 'Unsupported file type. Please upload an image, video, or PDF.',
        autoClose: 2000,
        type: "warning",
        isLoading: false,
      });
      return;
    }


    const formData = new FormData();
    formData.append('description', postText);
    formData.append('type', fileType);
    formData.append('file', file);

    try {
      await axios.post(
        'https://islamic-world-1.onrender.com/api/users/upload',
        formData,
        {
          method: "POST",
          headers: {
            'x-auth-token': auth.user.token,
            'Content-Type': 'multipart/form-data',
          },

          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            toast.update(uploadToast, {
              render: `Uploading: ${percentCompleted}%`,
            });
          },

        }
      );
      setFile("")
      fetchContent()
      toast.update(uploadToast, {
        render: 'File uploaded successfully.',
        autoClose: 2000,
        type: "success",
        isLoading: false
      });
    } catch (error) {
      toast.update(uploadToast, {
        render: 'Error uploading file: ' + error.message,
        autoClose: 2000,
        type: "error",
        isLoading: false
      });
    }
  };

  useEffect(() => {
    fetchContent();
  })

  const videoStyle = {
    width: '100%',
    border: '2px solid black',
    borderRadius: '10px'
  };

  return (

    <section className="post-sec">
      <Container>

        <div className="user-pofile">
          <Row className="justify-content-around gy-4">
            <Col sm={12} md={6} lg={6}>
              <div>
                <div>
                  <ToastContainer stacked />

                  {isAuthenticated ? <form onSubmit={handleSubmit}>
                    <ul className="post-form">
                      <li>
                        <div className="User-img">
                          <img src={user} alt="" />
                        </div>
                      </li>
                      <li>
                        <div className="txt-box">
                          <input placeholder="What do you wanna post today?"
                            value={postText} required
                            onChange={handleTextChange}
                          />
                        </div>
                      </li>
                      <li>
                        <div className="txt-box">
                          <input placeholder="" multiple id="file-upload" hidden type="file"
                            onChange={handleFileChange}
                          />
                          {
                            isAuthenticated ?
                              <label role="button" htmlFor="file-upload"><i class="fa-solid fa-paperclip"></i></label>
                              :
                              <label role="button" onClick={() => handleClick()}><i className="fa-solid fa-upload"></i></label>
                          }
                        </div>
                      </li>
                      <li>
                        <div className="btn-post">
                          {isAuthenticated ?
                            <button type="submit" > <i className="fa-light fa-paper-plane"></i> </button>
                            :
                            <button type="button" onClick={() => handleClick()} > <i className="fa-light fa-paper-plane"></i> </button>

                          }
                        </div>
                      </li>
                    </ul>
                  </form> : null}


                </div>
                <div className="user-post">


                  {
                    content && content.length > 0 ? (
                      content.map((item, index) => {
                        if (isAuthenticated || item.isApproved) {
                          return (
                            <div className="User" key={index}>
                              <div className="d-flex justify-content-between">
                                <ul className="user-d">
                                  <li>
                                    <div className="User">
                                      <img className="img-fluid" src={user} alt="User" />
                                    </div>
                                  </li>
                                  <li>
                                    <h3>{item.userId ? item.userId.name : null}</h3>
                                  </li>
                                </ul>
                                {!item.isApproved && (
                                  <h3>
                                    <i className="text-danger fa-regular fa-clock-rotate-left"></i>
                                  </h3>
                                )}
                              </div>
                              <div className="user-post-img">
                                {
                                  item.type == "image" ?

                                    <a href={`https://islamic-world-1.onrender.com/${item.path}`} data-fancybox="gallery">
                                      <img
                                        className="img-fluid"
                                        src={`https://islamic-world-1.onrender.com/${item.path}`}
                                        alt="Post"
                                      />
                                    </a>

                                    :
                                    item.type == "video" ?
                                      <a href={`https://islamic-world-1.onrender.com/${item.path}`} data-fancybox="gallery">
                                        <video controls style={videoStyle}>
                                          <source src={`https://islamic-world-1.onrender.com/${item.path}`} type="video/mp4" />
                                          Your browser does not support the video tag.
                                        </video>
                                      </a>

                                      :
                                      <a href={`https://islamic-world-1.onrender.com/${item.path}`}>
                                        Download File
                                      </a>

                                }


                              </div>
                              <div className="content">
                                {/* <h3>{item.userId.name}</h3> */}
                                <p>{item.description}</p>
                              </div>
                            </div>
                          );
                        }
                      })
                    ) : (
                      <div className="alert alert-warning text-light">No content available</div>
                    )
                  }






                </div>

              </div>
            </Col>
            <Col sm={12} md={5} lg={5}>
              <div className="referral-side">
                <div className="img-post">
                  <img className="img-fluid" src={invitePost} />
                </div>
                <Row className="justify-content-center">
                  <Col sm={12} md={10} lg={10}>
                    <ReferralBox />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </Container>


    </section>
  )
}

export default LandingPage
