import axios from "axios";
import { useEffect, useState } from "react";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { toast } from "react-toastify";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import 'datatables.net-bs5';
import videoImage from "./../assets/img/video_image.jpg"


const Posts = () => {

  const auth = useAuthUser();
  const [content, setContent] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchContent = async (page) => {
    setLoading(true)
    try {
      const response = await axios.get(
        'https://islamic-world-1.onrender.com/api/admin/get-content/',
        {
          params: { page, limit: 10 },

          headers: {
            'x-auth-token': auth.user.token
          }
        }
      );
      setContent(response.data.data.content);
      setTotalPages(response.data.data.totalPages);

    } catch (error) {
      toast.error('Error fetchinsg posts: ' + error.message,
        { autoClose: 2000 })
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchContent(currentPage);
  }, [currentPage]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleApprove = async (id) => {
    const approve = toast.loading("Approving..", {
      autoClose: false,
      toastId: "approve"
    })
    try {
      await axios.put(
        "https://islamic-world-1.onrender.com/api/admin/approve-content/" + id, {},
        {
          headers: {
            'x-auth-token': auth.user.token
          }
        }
      );

      setContent((prevContent) =>
        prevContent.map((user) =>
          user._id === id ? { ...user, isApproved: true } : user
        )
      );
      toast.update(approve, {
        type: "success",
        render: 'Post has approved: ',
        isLoading: false,
        autoClose: 2000
      })
    } catch (error) {

      toast.update(approve, {
        type: "error",
        render: 'Error approving Post: ' + error.message,
        isLoading: false,
        autoClose: 2000
      })
    }
  }



  return (

    <div className="row">
      <div className="col-12">
        <div className="card mb-4">
          <div className="card-header d-flex justify-content-between pb-0">
            <h6>Posts</h6>
            {
              Loading ? <div className="spinner-border text-primary" role="status"> </div> : ""
            }
          </div>
          <div className="card-body px-0 pt-0 pb-2">
            <div className="table-responsive p-0">
              <table className="table align-items-center mb-0" >
                <thead>
                  <tr>
                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">post</th>
                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Type</th>
                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">description</th>
                    <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    Loading ? <tr ><td colSpan={3} ></td></tr> :
                      content && content.length > 0 ? (content.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <div className="d-flex px-2 py-1">
                              <div>
                                <a href={"https://islamic-world-1.onrender.com/" + item.path} data-fancybox="gallery">
                                  {item.type === "image"
                                    ?
                                    <img src={"https://islamic-world-1.onrender.com/" + item.path} className="avatar avatar-sm me-3" alt="user1" />

                                    :
                                    <img src={videoImage} className="avatar avatar-sm me-3" alt="user1" />

                                  }

                                </a>

                              </div>
                              <div className="d-flex flex-column justify-content-center">
                                <h6 className="mb-0 text-sm">{item.userId ? item.userId.name : "n/a"}</h6>
                                <p className="text-xs text-secondary mb-0">{item.userId ? item.userId.email : "n/a"}</p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <p className="text-xs text-secondary mb-0">{item.type ?? "n/a"}</p>
                          </td>
                          <td>
                            <p className="text-xs text-secondary mb-0">{item.description ?? "n/a"}</p>
                          </td>
                          <td className="align-middle text-center text-sm">
                            {item.isApproved ?
                              <span className="badge badge-sm bg-gradient-success" >Approved</span>
                              :
                              <span onClick={() => handleApprove(item._id)} role="button" className="badge badge-sm  bg-gradient-secondary">Pending</span>

                            }
                          </td>
                        </tr>

                      ))
                      ) : <tr ><td colSpan={3} className="align-middle text-center table-danger">Posts Not Found</td></tr>}

                </tbody>
              </table>
            </div>
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-end">
                <li className={`page-item active ${currentPage === 1 ? "disabled" : ""}  `}>
                  <button className="page-link text-light" type="button"
                    onClick={handlePreviousPage}
                  >
                    <i className="fa fa-angle-left"></i>
                    <span className="sr-only">Previous</span>
                  </button>
                </li>
                <li className="page-item" ><span className="page-link border-none" >{currentPage}</span></li>
                <li className="page-item "><span className="page-link" >-</span></li>
                <li className="page-item"><span className="page-link" >{totalPages}</span></li>
                <li className={`page-item active  ${currentPage === totalPages ? "disabled" : ""}`}>
                  <button className="page-link text-light " type="button"
                    onClick={handleNextPage}
                  >
                    <i className="fa fa-angle-right"></i>
                    <span className="sr-only">Next</span>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>


  )
}

export default Posts