// import { Link } from "react-router-dom";
import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Banner = ({ title, additionalClass  }) => {
  return (
    <section className="main-banner">
      <Container>
        <Row className={` ${additionalClass ? additionalClass : ''}`}>
          <Col sm={12} md={6} lg={5}>
            <div className="title">
              <h1>{title}</h1>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Banner;
