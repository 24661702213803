import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Banner from "../component/banner";
import Icon1 from "../assets/images/fun-facts-1.png";
import Icon2 from "../assets/images/fun-facts-2.png";
import Icon3 from "../assets/images/fun-facts-3.png";
import Icon4 from "../assets/images/fun-facts-4.png";

const ContactUs = () => {
  const dynamicText = "Contact Us";
  return (
    <>
      <Banner title={dynamicText} additionalClass="justify-content-center text-center" />
      <section className="contact-sec">
        <Container>
          <Row className="justify-content-center gy-4">
            <Col sm={12} md={10} lg={10}>
              <div className="title">
                <h2>get in touch</h2>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui sunt libero dignissimos sint accusamus mollitia, eum, voluptate veniam</p>
              </div>
            </Col>
            <Col sm={12} md={7} lg={7}>
              <form action="">
                <Row className="gy-4 justify-content-center">
                  <Col sm={12} md={6} lg={6}>
                    <div className="txt-box">
                      <input placeholder="your name" type="text" />
                    </div>
                  </Col>
                  <Col sm={12} md={6} lg={6}>
                    <div className="txt-box">
                      <input placeholder="your email" type="text" />
                    </div>
                  </Col>
                  <Col sm={12} md={12} lg={12}>
                    <div className="txt-box">
                      <input placeholder="your phone" type="text" />
                    </div>
                  </Col>
                  <Col sm={12} md={12} lg={12}>
                    <div className="txt-box">
                      <textarea
                        placeholder="your message"
                        name=""
                        id=""
                        cols="30"
                        rows="3"
                      ></textarea>
                    </div>
                  </Col>
                  <Col sm={12} md={12} lg={12}>
                    <div className="btn-sub">
                      <button>submit now</button>
                    </div>
                  </Col>
                </Row>
              </form>
            </Col>
            <Col className="panel" sm={12} md={5} lg={5}>
                <div className="map h-100">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3618.662378706129!2d67.10768433094532!3d24.90949544445793!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb338d8ce7093e9%3A0x32e5ded8232f5425!2sBlock%2010A%20Block%2010%20A%20Gulshan-e-Iqbal%2C%20Karachi%2C%20Karachi%20City%2C%20Sindh%2C%20Pakistan!5e0!3m2!1sen!2s!4v1725806400171!5m2!1sen!2s"></iframe>
                </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="islamic-counter-sec contact">
        <div className="container">
          <Row className="fun-facts gy-4">
            <Col sm={12} md={3} lg={3} className="panel">
              <div className="islamic-mosque h-100">
                <span>
                  <i className="fa-solid fa-phone"></i>
                </span>
                <a href="tel:+923342525497">+923342525497</a>
                <a href="tel:+923162405080">+923162405080</a>
              </div>
            </Col>
            <Col sm={12} md={3} lg={3} className="panel">
              <div className="islamic-mosque h-100">
                <span>
                  <i className="fa-solid fa-envelope"></i>
                </span>
                <a href="mailto:ehsan.sons00@gmail.com">ehsan.sons00@gmail.com</a>
              </div>
            </Col>
            <Col sm={12} md={3} lg={3} className="panel">
              <div className="islamic-mosque h-100">
                <span>
                  <i className="fa-solid fa-location-dot"></i>
                </span>
                <p>UG 20 Plot D-06 Block 10A Glamour Mall Gulshan E Iqbal</p>
              </div>
            </Col>
            <Col sm={12} md={3} lg={3} className="panel">
              <div className="islamic-mosque h-100">
                <span>
                  <i className="fa-solid fa-fax"></i>
                </span>
                <a href="tel:+1234567890">+1234567890</a>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
