import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import logo from "../../assets/images/logo.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";

const CreateAccountModal = ({ show, handleClose, handleShow }) => {
  const [fullName, setFullName] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const isValidEmail = (email) => {

    return email.includes('@') && email.endsWith('.com');
  };

  const validatePassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(password);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true)
    const signUpToast = toast.loading("Creating Account...", {
      toastId: "singup_toast",
      autoClose: false
    })

    if (!fullName || !email || !password) {
      toast.update(signUpToast, {
        render: "Please fill in all required fields.",
        type: "info",
        autoClose: 2000,
        isLoading: false
      })
      setDisabled(false)
      return;
    }





    if (!isValidEmail(email)) {
      toast.update(signUpToast, {
        render: "Please enter a valid email address.",
        type: "info",
        autoClose: 2000,
        isLoading: false
      })
      setDisabled(false)
      return
    }

    if (!validatePassword(password)) {
      toast.update(signUpToast, {
        render: "Your password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character.",
        type: "info",
        autoClose: 2000,
        isLoading: false
      })
      setDisabled(false)
      return
    }

    try {
      const response = await fetch('https://islamic-world-1.onrender.com/api/users/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: fullName, email, password, referralCode }),
      });

      const data = await response.json();

      if (response.ok) {

        handleClose("createAccount")
        handleShow("subscription")
        toast.update(signUpToast, {
          render: 'Registration Successfull',
          type: "success",
          autoClose: 2000,
          isLoading: false
        })

        setFullName('');
        setReferralCode('');
        setEmail('');
        setPassword('');

      } else {
        toast.update(signUpToast, {
          render: data.message,
          type: "error",
          autoClose: 2000,
          isLoading: false
        })
      }
    } catch (err) {
      toast.update(signUpToast, {
        render: err.message,
        type: "error",
        autoClose: 2000,
        isLoading: false
      })
    } finally {
      setDisabled(false)
    }


  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="md"
      className="modal-login-sec modal-create-account"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Row className="justify-content-center">
          <Col sm={12} md={9} lg={9}>
            <div className="logo">
              <img className="img-fluid" src={logo} alt="Logo" />
            </div>
            <div className="title">
              <h2>Register with <span>Islamic World!</span></h2>
            </div>
            <form onSubmit={handleSubmit}>
              <Row className="gy-4">
                <Col sm={12}>
                  <div className="txt-box">
                    <input
                      placeholder="Full Name"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      required
                    />
                  </div>
                </Col>
                <Col sm={12}>
                  <div className="txt-box">
                    <input
                      placeholder="Enter Referral Code"
                      value={referralCode}
                      onChange={(e) => setReferralCode(e.target.value)}
                    />
                  </div>
                </Col>
                <Col sm={12}>
                  <div className="txt-box">
                    <input
                      placeholder="Enter Email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                </Col>
                <Col sm={12}>
                  <div className="txt-box pass">
                    <input
                      placeholder="Password"
                      type={showPassword ? 'text' : 'password'}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    <i
                      className={`fa-regular ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}
                      onClick={togglePasswordVisibility}
                      style={{ cursor: 'pointer' }}
                    ></i>
                  </div>
                </Col>
                <Col sm={12}>
                  <div className="txt-check">
                    <label className="color-red">Your password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character.</label>
                  </div>
                </Col>
                <Col sm={12}>
                  <div className="btn-sub">
                    <button type="submit" disabled={disabled}>
                      Create Account
                    </button>
                  </div>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default CreateAccountModal;
