// import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../component/Header";
import Footer from "../component/Footer";
import ScrollToTop from "../../user/component/ScrollToTop";

import LoginModal from "../component/modals/LoginModal";
import CreateAccountModal from "../component/modals/CreateAccountModal";
import VerifyEmailModal from "../component/modals/VerifyEmailModal";
import SubscriptionModal from "../component/modals/SubscriptionModal";
import RegistrationSkipModal from "../component/modals/RegistrationSkipModal";
import SuccessModal from "../component/modals/SuccessModal";
import { useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import ForgotPasswordModal from "../component/modals/ForgotPasswordModal";
import ResetPasswordModal from "../component/modals/ResetPasswordModal";


const UserLayout = () => {

  const isAuthenticated = useIsAuthenticated();
  const auth = useAuthUser();

  const [modalType, setModalType] = useState("");
  const handleShow = (type) => setModalType(type);
  const handleClose = () => setModalType("");
  const [resetToken, setResetToken] = useState("");

  if (isAuthenticated && auth.user.role === "admin") {
    return <Navigate to="/admin/users" />;
  }

  return (
    <>
      <ScrollToTop />

      <Header handleShow={handleShow} />


      <Outlet />

      <Footer handleShow={handleShow} />

      <ResetPasswordModal resetToken={resetToken} show={modalType === "reset"} handleClose={handleClose} handleShow={handleShow} />
      <ForgotPasswordModal setResetToken={setResetToken} show={modalType === "forgot"} handleClose={handleClose} handleShow={handleShow} />
      <LoginModal show={modalType === "login"} handleClose={handleClose} handleShow={handleShow} />
      <CreateAccountModal show={modalType === "createAccount"} handleClose={handleClose} handleShow={handleShow} />
      <VerifyEmailModal show={modalType === "verifyEmail"} handleClose={handleClose} handleShow={handleShow} />
      <SubscriptionModal show={modalType === "subscription"} handleClose={handleClose} handleShow={handleShow} />
      <RegistrationSkipModal show={modalType === "registrationSkip"} handleClose={handleClose} handleShow={handleShow} />
      <SuccessModal show={modalType === "success"} handleClose={handleClose} />
    </>


  );
};

export default UserLayout;
