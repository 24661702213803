import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0); // A delay of 0 milliseconds can sometimes resolve timing issues

    return () => clearTimeout(timer);
  }, [pathname]);

  return null;  
};

export default ScrollToTop;
