import axios from "axios";
import { useEffect, useState } from "react";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { toast } from "react-toastify";


const Transactions = () => {

const auth = useAuthUser();
const [content, setContent] = useState([]);
const [Loading, setLoading] = useState(false);
const [currentPage, setCurrentPage] = useState(1);
const [totalPages, setTotalPages] = useState(1);
const fetchContent = async (page) => {
  setLoading(true);
    try {
      const response = await axios.get(
        'https://islamic-world-1.onrender.com/api/admin/get-transection',
        {
          params: { page, limit: 10 },
          headers: {
            'x-auth-token': auth.user.token
          }
        }
      );
      
      setContent(response.data.data);
      setTotalPages(response.data.data.totalPages);
 
    } catch (error) {

      toast.error(
        'Error fetching Transactions: ' + error.message,{
          autoClose:2000
      })
    }finally{setLoading(false)}
  };
    

  
    const handleApprove = async (id) =>{

      const approve = toast.loading("Approving..",{
        autoClose:false,
        toastId:"approve"
      })
        try {
             await axios.post(
              "https://islamic-world-1.onrender.com/api/admin/approve-transection/",{"transectionId":id,"status":"completed"},
              {
                headers: {
                  'Content-Type': 'application/json',
                  'x-auth-token': auth.user.token
                }
              }
            );

            setContent((prevContent) =>
              prevContent.map((transection) =>
                transection._id === id ? { ...transection, status: "completed" } : transection
              )
            );

            toast.update(approve,{
              type:"success",
              render:'Transactions has approved: ' ,
              isLoading:false,
              autoClose:2000
            })
          } catch (error) {
            toast.update(approve,{
              type:"error",
              render:'Error approving Transaction: ' + error.message,
              isLoading:false,
              autoClose:2000
            })
          }
    }


    useEffect(() => {
      fetchContent(currentPage);
    }, [currentPage]);
  
    const handleNextPage = () => {
      if (currentPage < totalPages) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    };
  
    const handlePreviousPage = () => {
      if (currentPage > 1) {
        setCurrentPage((prevPage) => prevPage - 1);
      }
    };   


    return(
        <div className="row">
        <div className="col-12">
          <div className="card mb-4">
            <div className="card-header d-flex justify-content-between pb-0">
              <h6>Transactions</h6>
              {
                Loading ? <div className="spinner-border text-primary" role="status"> </div>:""
              }
            </div>
            <div className="card-body px-0 pt-0 pb-2">
              <div className="table-responsive p-0">
                <table className="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th className="text-uppercase text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Title</th>
                      <th className="text-uppercase  text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Account Details</th>
                      <th className="text-uppercase text-center text-secondary text-xxs font-weight-bolder opacity-7 ps-2">description</th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">type</th>
                      <th className="text-uppercase text-center text-secondary text-xxs font-weight-bolder opacity-7 ps-2">amount</th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      
                      Loading ?<tr ><td colSpan={6} ></td></tr> :
                    content && content.length > 0 ? content.map((item,index)=>(
                        <tr key={index}>
                        <td>
                          <div className="d-flex px-2 py-1">
                            <div>
                              <img src="../assets/img/team-2.jpg" className="avatar avatar-sm me-3" alt="user1" />
                            </div>
                            <div className="d-flex flex-column justify-content-center">
                              <h6 className="mb-0 text-sm">{item.userId.name}</h6>
                              <p className="text-xs text-secondary mb-0">{item.userId.email}</p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <p className="text-xs text-secondary mb-0"><span className="font-weight-bold" >account title: </span>{item.accountTitle}</p>
                          <p className="text-xs text-secondary mb-0"><span className="font-weight-bold" >account number: </span>{item.accountNumber}</p>
                          <p className="text-xs text-secondary mb-0"><span className="font-weight-bold" >bank name: </span>{item.bankName}</p>
                        </td>
                        <td className="align-middle text-center">
                          <span className={`${"text-secondary"} text-s font-weight-bold`}>{item.description}</span>
                        </td>
                        <td className="align-middle text-center">
                          <span className={`${"text-secondary" } text-s font-weight-bold`}>{item.type}</span>
                        </td>
                        <td className="align-middle text-center">
                          <span className={`${item.amount > 0 ? "text-success" : "text-danger" } text-s font-weight-bold`}>${item.amount}</span>
                        </td>
                      
                        
                        <td className="align-middle text-center text-sm">
                        {item.status === "completed" ? 
                          <span  className="badge badge-sm bg-gradient-success" >Completed</span>
                        :
                        <span onClick={() => handleApprove(item._id)}  role="button" className="badge badge-sm  bg-gradient-secondary">Pending</span>
                        
                        }
                        </td>
                      </tr>
                    ))
                  
                : <tr ><td colSpan={6} className="align-middle text-center table-danger">Transactions Not Found</td></tr>    
                }
                  </tbody>
                </table>
              </div>
              <nav aria-label="Page navigation example">
  <ul className="pagination justify-content-end">
    <li className={`page-item active ${currentPage === 1 ? "disabled" : ""}  `}>
      <button  className="page-link text-light" type="button"
           onClick={handlePreviousPage}
      >
        <i className="fa fa-angle-left"></i>
        <span className="sr-only">Previous</span>
      </button>
    </li>
    <li className="page-item" ><span className="page-link border-none" >{currentPage}</span></li>
    <li className="page-item "><span className="page-link" >-</span></li>
    <li className="page-item"><span className="page-link" >{totalPages}</span></li>
    <li className={`page-item active  ${currentPage === totalPages ? "disabled" : "" }`}>
      <button className="page-link text-light " type="button"
         onClick={handleNextPage}
      >
        <i className="fa fa-angle-right"></i>
        <span className="sr-only">Next</span>
      </button>
    </li>
  </ul>
</nav> 
            </div>
          </div>
        </div>
      </div>
    )
}
export default Transactions