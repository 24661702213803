
import "../assets/css/nucleo-svg.css"
import "../assets/css/nucleo-icons.css"
import "../assets/css/nucleo-svg.css"
import "../assets/css/argon-dashboard.css?v=2.0.4"
import Aside from "../component/Aside"
import Nav from "../component/Nav"
import { ToastContainer } from "react-toastify"
import { Navigate, Outlet } from "react-router-dom"
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated"
import useAuthUser from "react-auth-kit/hooks/useAuthUser"
import Dashboard from "./Dashboard"

const AdminLayout = () => {

  const isAuthenticated = useIsAuthenticated();
  const auth = useAuthUser();

  if (!isAuthenticated || auth.user.role !== "admin") {
    return <Navigate to="/" />;
  }


  return (
    <>
      <ToastContainer stacked />

      <div className="min-height-300 position-absolute w-100" style={{ backgroundColor: "#d9b343" }} ></div>

      <Aside />
      <main className="main-content position-relative border-radius-lg ">
        {/* Navbar  */}
        <Nav />
        {/* End Navbar  */}
        <div className="container-fluid py-4">
          <Dashboard />

          <Outlet />

          {/* <Footer /> */}
        </div>
      </main>
    </>
  )
}

export default AdminLayout