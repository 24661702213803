
// user imports
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import UserLayout from "./user/pages/Layout";
import 'react-toastify/dist/ReactToastify.css';
import Referral from "./user/pages/ReferralPage";
import createStore from 'react-auth-kit/createStore';
import AuthProvider from 'react-auth-kit';
import AboutUs from "./user/pages/AboutUs";
import ContactUs from "./user/pages/ContactUs";
import Home from './user/pages/Home';

// admin  imoprts
import AdminLayout from "./admin/pages/Layout";
import Posts from './admin/pages/Posts';
import Users from './admin/pages/Users';
import Transactions from './admin/pages/Transactions';
import Profile from './admin/pages/Profile';


function App() {



  const store = createStore({
    authName: '_auth',
    authType: 'cookie',
    cookieDomain: window.location.hostname,
    cookieSecure: window.location.protocol === 'https:',
  });



  return (
    <AuthProvider store={store}>
      <Router>
        <Routes>
          <Route path='/' element={<UserLayout />}>
            <Route path="" element={<Home />} />
            <Route path="about-us" element={<AboutUs />} />
            <Route path="referral" element={<Referral />} />
            <Route path="contact-us" element={<ContactUs />} />
          </Route>

          <Route path='admin' element={<AdminLayout />}>
            <Route path="posts" element={<Posts />} />
            <Route path="users" element={<Users />} />
            <Route path="billing" element={<Transactions />} />
            <Route path="profile" element={<Profile />} />
          </Route>
        </Routes>


      </Router>
    </AuthProvider>
  );
}

export default App;
