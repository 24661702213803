import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import logo from "../../assets/images/logo.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const SubscriptionModal = ({ show, handleClose, handleShow }) => {
   // State to manage the selected option
  const [selectedOption, setSelectedOption] = useState('');

  // Information for each option
  const info = {
    'Easy Paisa': '03452525490',
    'Sadapay': '03162405080',
    'Bank Account': {
      Name: 'Syed Owais Shah',
      IBN: 'PK20 BAHL 1077098102540902',
      AccountNumber: '1077098102540902',
      BankName: 'LOCAL',
    },
  };

  // Handle dropdown change
  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  // Function to render additional information based on selected option
  const renderInfo = () => {
    if (selectedOption === 'Bank Account') {
      const bankInfo = info['Bank Account'];
      return (
        <div>
          <h2>Bank Account Information:</h2>
          <p><strong>Bank account title :</strong> {bankInfo.Name}</p>
          <p><strong>IBN number :</strong> {bankInfo.IBN}</p>
          <p><strong>Account number :</strong> {bankInfo.AccountNumber}</p>
          <p><strong>Bank name :</strong> {bankInfo.BankName}</p>
        </div>
      );
    }

    if (selectedOption && info[selectedOption]) {
      return (
        <div>
          <h2>Information:</h2>
          <p><strong>{selectedOption}:</strong> {info[selectedOption]}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="md"
      className="modal-login-sec modal-subscription"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Row className="justify-content-center">
          <Col sm={12} md={9} lg={9}>
            <div className="logo">
              <img className="img-fluid" src={logo} alt="Logo" />
            </div>
            <div className="title">
              <h2>
                Subscribe to <span>Islamic World</span> for only <span>Rs. 5000</span> /Lifetime
              </h2>
              <p>You can easily earn it back through our referral program!</p>
            </div>
            <Row className="gy-4">
              <Col sm={12}>
                {/* <div className="txt-box">
                  <textarea rows={4}></textarea>
                </div> */}
              </Col>
              <Col sm={12}>
                <div className="txt-box">
                  {/* Dropdown menu */}
                  <select onChange={handleChange} value={selectedOption}>
                    <option value="">Select an option</option>
                    <option value="Easy Paisa">Easy Paisa</option>
                    <option value="Sadapay">Sadapay</option>
                    <option value="Bank Account">Bank Account</option>
                  </select>
                </div>
              </Col>
              <Col sm={12}>
                <div className="account-detail">
                  {/* Display additional information based on selected option */}
                  {/* {selectedOption && (
                      <p>Information: {info[selectedOption]}</p>
                  )} */}
                  {renderInfo()}
                </div>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <div className="txt-check justify-content-center">
                  <label className="text-center ">
                    After transferring the Rs. 5000 subscription fee, please
                    share the screenshot with us on WhatsApp : <a href="https://wa.me/+923422383023" target="_blank" rel="noopener noreferrer" >+92 342 2383023</a>.
                  </label>
                </div>
              </Col>
              <Col sm={12}>
                <div className="btn-sub">
                  <button onClick={() => handleShow('login')}>Registration </button>
                </div>
              </Col>
              <Col sm={12}>
                <div className="forgot text-center">
                  <a href="#" onClick={() => handleClose('subscription')} >Skip Registration</a>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>  
  );
};

export default SubscriptionModal;
