import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import logo from "../../assets/images/logo.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import { toast } from 'react-toastify';


const LoginModal = ({ show, handleClose, handleShow }) => {

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  
  const signIn = useSignIn()
  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [disabled, setDisabled] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true)
    const toastId = toast.loading('Logging in...', {
      toastId:"login_toast",
      autoClose: false,
    });

    if (email === '' || password === '') {
      toast.update(toastId, {
        render: "Please fill in all fields.",
        type: "info",
        autoClose: 2000,
        isLoading:false
      });
      return;
    }

    try {
      const response = await fetch('https://islamic-world-1.onrender.com/api/users/signin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();
      

      if (response.ok) {  
      signIn({
          auth: {
              token: data.data.token,
              type: 'Bearer'
          },
          userState: {
              user: data.data,
          }
      })

        setEmail('');
        setPassword('');

        toast.update(toastId, {
          render: "Login Successfull",
          type: "success",
          autoClose: 2000,
          isLoading:false
        });
        handleClose(); // Close modal on successful login
        
        setTimeout(() => {
          window.location.href=""
          }, 2000);
          
        window.location.href=''
      } else {
        toast.update(toastId, {
          render:data.message,
          type: "error",
          autoClose: 2000,
          isLoading:false
        });
      }
    } catch (err) {
      toast.update(toastId, {
        render: err.message,
        type: "error",
        autoClose: 2000,
        isLoading:false
      });
     
    }finally{
setDisabled(false)
    } 
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="md"
      className="modal-login-sec"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Row className="justify-content-center">
          <Col sm={12} md={9} lg={9}>
            <div className="logo">
              <img className="img-fluid" src={logo} alt="Logo" />
            </div>
            <div className="title">
              <h2>
                Welcome to <span>Islamic World!</span>
              </h2>
            </div>
            <form onSubmit={handleSubmit}>
              <Row className="gy-4">
                <Col sm={12}>
                  <div className="txt-box">
                    <input
                      placeholder="Email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                </Col>
                <Col sm={12}>
                  <div className="txt-box pass">
                    <input
                      placeholder="Password"
                      type={showPassword ? 'text' : 'password'}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    <i
                    className={`fa-regular ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}
                    onClick={togglePasswordVisibility}
                    style={{ cursor: 'pointer' }}
                  ></i>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="txt-check">
                    <input
                      type="checkbox"
                      id="remember"
                      className="form-check-input"
                    />
                    <label htmlFor="remember">Remember me</label>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="forgot">
                    <a href="#" onClick={() => handleShow('forgot')}>Forgot password?</a>
                  </div>
                </Col>
                <Col sm={12}>
                  <div className="btn-sub">
                    <button type="submit" disabled={disabled} >
                       Login
                    </button>
                  </div>
                </Col>
                <Col sm={12}>
                  <div className="btn-sub create">
                    <button
                      type="button"
                      onClick={() => handleShow('createAccount')}
                    >
                      Create An Account
                    </button>
                  </div>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default LoginModal;
