import React from "react";
import { Modal } from "react-bootstrap";
import logo from "../../assets/images/logo.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const RegistrationSkipModal = ({ show, handleClose, handleShow }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="md"
      className="modal-login-sec modal-registration-skip"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
      <Row className="justify-content-center">
          <Col sm={12} md={9} lg={9}>
            <div className="logo">
              <img className="img-fluid" src={logo} alt="Logo" />
            </div>
            <div className="title">
              <h2> Are you sure you want to skip the registration?</h2>
              <p>You won't be able to see the content available on the website. To access all the content, you must be subscribed to Islamic World.</p>
            </div>
            <Row className="gy-4">
              <Col sm={12}>
                <div className="btn-sub">
                  <button onClick={() => handleShow('subscription')}>No, Subscribe </button>
                </div>
              </Col>
              <Col sm={12}>
                <div className="btn-sub">
                  <button onClick={() => handleClose('registrationSkip')}>YEs, Skip </button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default RegistrationSkipModal;
