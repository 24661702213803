import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import logo from "../assets/images/logo.png";
import { Nav } from "react-bootstrap";

const Header = ({ handleShow }) => {
  return (
    <>
      <footer >
        <div className="container">
          <div className="row Information pb-0">
            <div className="col-lg-4 col-md-6">
              <div className="widget-title">
                <h3>Information</h3>
                <p>Lorem ipsum, or lipsum as it is some
                  times  known, is dummy text used in
                  laying out pri nt, graphic or web lirm
                  Lorem ipsum, or lip designs.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="Information widget-title">
                <h3>Contact info</h3>
                <div className="contact-info">
                  <span className="icons">
                    <i className="fa-solid fa-phone"></i>
                  </span>
                  <div>
                    <h5>Phone no:</h5>
                    <a href="callto:+923162405080">+923162405080</a>
                  </div>
                </div>
                <div className="contact-info">
                  <span className="icons">
                    <i className="fa-solid fa-envelope"></i>
                  </span>
                  <div>
                    <h5>Email Address:</h5>
                    <a href="mailto:ehsan.sons00@gmail.com">ehsan.sons00@gmail.com</a>
                  </div>
                </div>
                <div className="contact-info">
                  <span className="icons">
                    <i className="fa-solid fa-location-dot"></i>
                  </span>
                  <h5>UG 20 Plot D-06 Block 10A Glamour Mall Gulshan E Iqbal</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="widget-title">
                <h3>Quick Links</h3>
                <ul>
                  <li><i className="fa-solid fa-angle-right"></i><Nav.Link as={Link} to="/" >Home</Nav.Link></li>
                  <li><i className="fa-solid fa-angle-right"></i><Nav.Link as={Link} to="/about-us">About Us</Nav.Link></li>
                  <li><i className="fa-solid fa-angle-right"></i><Nav.Link as={Link} to="/referral">Referral</Nav.Link></li>
                  <li><i className="fa-solid fa-angle-right"></i><Nav.Link as={Link} to="/contact-us">Contact Us</Nav.Link></li>
                  {/* <li><i className="fa-solid fa-angle-right"></i><Nav.Link as={Link} to="/">Quran Hifz Classes</Nav.Link></li>
                  <li><i className="fa-solid fa-angle-right"></i><Nav.Link as={Link} to="/">About Ibadat</Nav.Link></li> */}

                </ul>
              </div>
            </div>
          </div>
          <div className="wpo-lower-footer">
            <p>Copyright ©<Link to="/"><b>Islamic World</b></Link> 2024 . All rights reserved.</p>
            <p className="text-center">Made by <a href="https://itpowersystems.com" target="_blank"> IT Power Systems </a></p>
            <div className="d-flex align-items-center">
              <a href="#"> Terms and Conditions</a>
              <div className="border"></div>
              <a href="#">Privacy Policy</a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Header;
