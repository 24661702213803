import useSignOut from "react-auth-kit/hooks/useSignOut"
import { NavLink, Link } from "react-router-dom";


const Nav = () => {

  const signOut = useSignOut()

  const logout = () => {
    signOut()
    document.location.href = "";
  }
  return (
    <nav className="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl " id="navbarBlur" data-scroll="false">
      <div className="container-fluid py-1 px-3">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
            <li className="breadcrumb-item text-sm"><span className="opacity-5 text-white" >Pages</span></li>
            <li className="breadcrumb-item text-sm text-white active" aria-current="page">Dashboard</li>
          </ol>
            <NavLink className="nav-link "
              exact
              activeclassname="active"
              to="/admin/profile">
          <h6 className="font-weight-bolder text-white mb-0">
              Dashboard Profile
          </h6>
            </NavLink>
        </nav>
        <div className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
          <div className="ms-md-auto pe-md-3 d-flex align-items-center">
            {/* <div className="input-group">
                <span className="input-group-text text-body"><i className="fas fa-search" aria-hidden="true"></i></span>
                <input type="text" className="form-control" placeholder="Type here..." />
              </div> */}
          </div>
          <ul className="navbar-nav  justify-content-end">
            <li className="nav-item d-flex align-items-center">
              <button className="nav-link text-white font-weight-bold px-0">
                <i className="fa fa-left-from-bracket me-sm-1"></i>
                <span className="d-sm-inline d-none" onClick={() => logout()}>Sign Out</span>
              </button>
            </li>



          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Nav