import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import logo from "../../assets/images/logo.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from 'react-toastify';


const ForgotPasswordModal = ({ show, handleClose, handleShow, setResetToken }) => {

  


  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const toastId = toast.loading('Logging in...', {
      toastId:"login_toast",
      autoClose: false,
    });

    if (email === '') {
      toast.update(toastId, {
        render: "Please fill in all fields.",
        type: "info",
        autoClose: 2000,
        isLoading:false
      });
      return;
    }

    try {
      const response = await fetch('https://islamic-world-1.onrender.com/api/users/forgot-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({email}),
      });

      const data = await response.json();
      

      if (response.ok) {  

        setResetToken(data.data.resetToken)
        setEmail('');
        toast.update(toastId, {
          render: data.message,
          type: "success",
          autoClose: 2000,
          isLoading:false
        });
      handleShow("reset")
          
      } else {
        toast.update(toastId, {
          render:data.message,
          type: "error",
          autoClose: 2000,
          isLoading:false
        });
      }
    } catch (err) {
      toast.update(toastId, {
        render: err.message,
        type: "error",
        autoClose: 2000,
        isLoading:false
      });
     
    }finally{
    } 
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="md"
      className="modal-login-sec"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Row className="justify-content-center">
          <Col sm={12} md={9} lg={9}>
            <div className="logo">
              <img className="img-fluid" src={logo} alt="Logo" />
            </div>
            <div className="title">
              <h2>
                Forgot <span>Password!</span>
              </h2>
            </div>
            <form onSubmit={handleSubmit}>
              <Row className="gy-4">
                <Col sm={12}>
                  <div className="txt-box">
                    <input
                      placeholder="Email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      
                    />
                  </div>
                </Col>
             
                <Col sm={12}>
                  <div className="btn-sub create">
                    <button
                      type="submit"
                  
                    >
                      Submit
                    </button>
                  </div>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ForgotPasswordModal;
