import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from '../assets/images/logo.png';
import user from '../assets/images/user.png';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated'
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import useSignOut from 'react-auth-kit/hooks/useSignOut';


const Header = ({ handleShow }) => {
  const isAuthenticated = useIsAuthenticated()
  const auth = useAuthUser()
  const signOut = useSignOut()

  const  logout = ()=>{
    signOut()
    document.location.href="";
  }

  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);



  return (
    <>
    <header className={`main-header ${scroll >= 100 ? "scroll" : ""}`}>
      <Navbar expand="lg" className="">
        <Container>
          <Navbar.Brand as={Link} className="nav-logo" to="/"><img className="img-fluid" src={logo}></img></Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto my-2 my-lg-0" navbarScroll>
            <Nav.Link as={Link} to="/"> Home</Nav.Link>
            <Nav.Link as={Link} to="/about-us">  About Us </Nav.Link>
            <Nav.Link as={Link} to="/referral"> Referral </Nav.Link>
            <Nav.Link as={Link} to="/contact-us"> Contact Us </Nav.Link>
            </Nav>
            <div className="d-flex social-icons">
             
              {
isAuthenticated ?
             <>
             <ul className="user-pro" >
                
                <li><img src={user}></img></li>
                <li>  <span>{isAuthenticated ? auth.user.name:"Salman Iqbal" } </span></li>
              </ul>
                    <ul className="social">
                    <li> <a role="button" onClick={logout}>logout </a> </li>
                    </ul>
                    </>
              : <ul className="user-pro" onClick={() => handleShow('login')}>
              <li role="button"> <span>Login</span> </li>
            </ul>
              }
        
              <ul className="social">
                <li><a><i className="fa-brands fa-youtube"></i></a></li>
                <li><a><i className="fa-brands fa-square-instagram"></i></a></li>
                <li><a><i className="fa-brands fa-facebook"></i></a></li>
              </ul>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>

    {/* <nav>
      <ul>
        <li><Link to="#" onClick={() => handleShow('login')}>Login</Link></li>
        <li><Link to="#" onClick={() => handleShow('createAccount')}>Create Account</Link></li>
        <li><Link to="#" onClick={() => handleShow('verifyEmail')}>Verify Email</Link></li>
        <li><Link to="#" onClick={() => handleShow('subscription')}>Subscription</Link></li>
        <li><Link to="#" onClick={() => handleShow('registrationSkip')}>Skip Registration</Link></li>
        <li><Link to="#" onClick={() => handleShow('success')}>Success</Link></li>
      </ul>
    </nav> */}
    </>
  );
};

export default Header;
