
import { useState } from "react";
import bgProfile from "../assets/img/bg-profile.jpg"
import team from "../assets/img/team-2.jpg"
import team1 from "../assets/img/team-1.jpg"
import { toast } from "react-toastify";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";


const Profile = () => {

    const auth = useAuthUser()

    const [fullName, setFullName] = useState('');
    const [referralCode, setReferralCode] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [disabled, setDisabled] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const isValidEmail = (email) => {

        return email.includes('@') && email.endsWith('.com');
    };

    const validatePassword = (password) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return regex.test(password);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setDisabled(true)
        const signUpToast = toast.loading("Profile Updating...", {
            toastId: "singup_toast",
            autoClose: false
        })

        if (email === "") {
            toast.update(signUpToast, {
                render: "Please fill in Email fields",
                type: "info",
                autoClose: 2000,
                isLoading: false
            })
            setDisabled(false)
            return;
        }


        if (!isValidEmail(email)) {
            toast.update(signUpToast, {
                render: "Please enter a valid email address.",
                type: "info",
                autoClose: 2000,
                isLoading: false
            })
            setDisabled(false)
            return
        }

        if (password && !validatePassword(password)) {
            toast.update(signUpToast, {
                render: "Your password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character.",
                type: "info",
                autoClose: 2000,
                isLoading: false
            })
            setDisabled(false)
            return
        }

        try {
            const response = await fetch('https://islamic-world-1.onrender.com/api/admin/update-profile', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': auth.user.token
                },
                body: JSON.stringify({ name: fullName, email, password, referralCode }),
            });

            const data = await response.json();

            if (response.ok) {

                console.log(data.data)
                toast.update(signUpToast, {
                    render: 'Profile updated successfully',
                    type: "success",
                    autoClose: 2000,
                    isLoading: false
                })


            } else {
                toast.update(signUpToast, {
                    render: data.message,
                    type: "error",
                    autoClose: 2000,
                    isLoading: false
                })
            }
        } catch (err) {
            toast.update(signUpToast, {
                render: err.message,
                type: "error",
                autoClose: 2000,
                isLoading: false
            })
        } finally {
            setDisabled(false)
        }


    };

    return (
        <>
            {/* <div className="card shadow-lg my-5 ">
                <div className="card-body p-3">
                    <div className="row gx-4">
                        <div className="col-auto">
                            <div className="avatar avatar-xl position-relative">
                                <img src={team1} alt="profile_image" className="w-100 border-radius-lg shadow-sm" />
                            </div>
                        </div>
                        <div className="col-auto my-auto">
                            <div className="h-100">
                                <h5 className="mb-1">
                                    {fullName}
                                </h5>
                                <p className="mb-0 font-weight-bold text-sm">
                                    {email}
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div> */}
            <div className="row">
                <div className="col-md-12">
                    <form onSubmit={handleSubmit}>
                        <div className="card">
                            <div className="card-header pb-0">
                                <div className="d-flex align-items-center">
                                    <p className="mb-0">Edit Profile</p>
                                    <button className="btn btn-primary btn-lg ms-auto" type="submit" disabled={disabled}>Submit</button>
                                </div>
                            </div>
                            <div className="card-body">
                                <p className="text-uppercase text-sm">User Information</p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="example-text-input" className="form-control-label">Username</label>
                                            <input className="form-control"
                                                type="text"
                                                placeholder="Full Name"
                                                value={fullName}
                                                onChange={(e) => setFullName(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="example-text-input" className="form-control-label">Email address</label>
                                            <input className="form-control" type="email"
                                                placeholder="Enter Email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="example-text-input" className="form-control-label">Referral code</label>
                                            <input className="form-control" type="text"
                                                placeholder="Enter Referral Code"
                                                value={referralCode}
                                                onChange={(e) => setReferralCode(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label for="example-text-input" className="form-control-label">Password</label>

                                        <div class="input-group mb-3">

                                            <input
                                                className="form-control"
                                                placeholder="Password"
                                                type={showPassword ? 'text' : 'password'}
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                required
                                            />
                                            <span class="input-group-text" id="basic-addon2" onClick={togglePasswordVisibility}>
                                                <i  className={`fa-regular ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}  style={{ cursor: 'pointer' }} ></i>
                                            </span>
                                        </div>


                                    </div>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
                {/* <div className="col-md-4">
                <div className="card card-profile">
                    <img src={bgProfile} alt="Image placeholder" className="card-img-top" />
                    <div className="row justify-content-center">
                        <div className="col-4 col-lg-4 order-lg-2">
                            <div className="mt-n4 mt-lg-n6 mb-4 mb-lg-0">
                                <a href="javascript:;">
                                    <img src={team} className="rounded-circle img-fluid border border-2 border-white" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="card-header text-center border-0 pt-0 pt-lg-2 pb-4 pb-lg-3">
                        <div className="d-flex justify-content-between">
                            <a href="javascript:;" className="btn btn-sm btn-info mb-0 d-none d-lg-block">Connect</a>
                            <a href="javascript:;" className="btn btn-sm btn-info mb-0 d-block d-lg-none"><i className="ni ni-collection"></i></a>
                            <a href="javascript:;" className="btn btn-sm btn-dark float-right mb-0 d-none d-lg-block">Message</a>
                            <a href="javascript:;" className="btn btn-sm btn-dark float-right mb-0 d-block d-lg-none"><i className="ni ni-email-83"></i></a>
                        </div>
                    </div>
                    <div className="card-body pt-0">
                        <div className="row">
                            <div className="col">
                                <div className="d-flex justify-content-center">
                                    <div className="d-grid text-center">
                                        <span className="text-lg font-weight-bolder">22</span>
                                        <span className="text-sm opacity-8">Friends</span>
                                    </div>
                                    <div className="d-grid text-center mx-4">
                                        <span className="text-lg font-weight-bolder">10</span>
                                        <span className="text-sm opacity-8">Photos</span>
                                    </div>
                                    <div className="d-grid text-center">
                                        <span className="text-lg font-weight-bolder">89</span>
                                        <span className="text-sm opacity-8">Comments</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-center mt-4">
                            <h5>
                                Mark Davis<span className="font-weight-light">, 35</span>
                            </h5>
                            <div className="h6 font-weight-300">
                                <i className="ni location_pin mr-2"></i>Bucharest, Romania
                            </div>
                            <div className="h6 mt-4">
                                <i className="ni business_briefcase-24 mr-2"></i>Solution Manager - Creative Tim Officer
                            </div>
                            <div>
                                <i className="ni education_hat mr-2"></i>University of Computer Science
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            </div>
        </>

    )
}

export default Profile