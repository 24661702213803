import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Banner from "../component/banner";
import ReferralBox from "../component/ReferralBox";
import ImgReferral from "../assets/images/img-referral.png";

const ReferralPage = () => {
  
  const dynamicText = "Rererral";

  return (
    <>
      <Banner title={dynamicText} additionalClass="justify-content-center text-center" />
      <section className="referral-sec">
        <Container>
          <Row className="justify-content-around gy-4">
            <Col sm={12} md={5} lg={5}>
              <Row className="justify-content-start">
                <Col sm={12} md={10} lg={10}>
                  <ReferralBox  />
                </Col>
              </Row>
            </Col>
            <Col sm={12} md={5} lg={5}>
              <img className="img-fluid" src={ImgReferral} />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="code-usage-sec">
        <Container>
          <Row className="justify-content-center ">
            <Col sm={12} md={11} lg={11}>
              <div className="title">
                <h2>Referral Code Usage and Earnings Criteria</h2>
                <p>
                  Please note that each referral code can only be used by 2
                  people within a 24-hour period.
                </p>
                <p>The referral earnings structure is as follows:</p>
              </div>
              <Row className="justify-content-center gy-4 gx-lg-4">
                <Col sm={12} md={4} lg={4}>
                  <div className="usage-boxes">
                    <span>01</span>
                    <p>
                      For the first two referrals, you will receive 50% of the
                      registration fee (Rs. 2500 each).
                    </p>
                  </div>
                </Col>
                <Col sm={12} md={4} lg={4}>
                  <div className="usage-boxes">
                    <span>02</span>
                    <p>
                      For the next eight referrals, you will receive 10% of the
                      registration fee (Rs. 500 each).
                    </p>
                  </div>
                </Col>
                <Col sm={12} md={4} lg={4}>
                  <div className="usage-boxes">
                    <span>03</span>
                    <p>
                      After 10 referrals, the referral percentage will become 8%
                      of the registration fee (Rs. 400).
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ReferralPage;
