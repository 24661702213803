import React from "react";
import { Modal } from "react-bootstrap";
import logo from "../../assets/images/logo.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const VerifyEmailModal = ({ show, handleClose, handleShow }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="md"
      className="modal-login-sec modal-verify-email"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Row className="justify-content-center">
          <Col sm={12} md={9} lg={9}>
            <div className="logo">
              <img className="img-fluid" src={logo} alt="Logo" />
            </div>
            <div className="title">
              <h2>Verify your Email Address</h2>
            </div>
            <Row className="gy-4">
              <Col sm={12} md={12} lg={12}>
                <div className="txt-check justify-content-center">
                  <label className="text-center ">
                  Enter the OTP Code we just sent you at someone@example.com
                  </label>
                </div>
              </Col>
              <Col sm={12}>
                <div className="txt-box">
                  <input placeholder="Enter Code" />
                </div>
              </Col>
              <Col sm={12}>
                <div className="btn-sub">
                  <button onClick={() => handleShow('registrationSkip')}>Verify </button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default VerifyEmailModal;
