import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated'
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import TransactionModal from "./modals/TransactionModal";
import { toast } from "react-toastify";
import axios from "axios";


const ReferralBox = () => {


  const [modalType, setModalType] = useState("");
  const handleShow = (type) => setModalType(type);
  const handleClose = () => setModalType("");

  const isAuthenticated = useIsAuthenticated();
  const auth = useAuthUser();

  const [wallet, setWallet] = useState({});
  
  const fetchWallet = async () => {
    try {
      const response = await axios.get(
        'https://islamic-world-1.onrender.com/api/users/wallet',
        {
          headers: {
            'x-auth-token': auth.user.token
          }
        }
      );
      
      setWallet(response.data.data);
    } catch (error) {
        console.error('Error fetching Wallet: ' + error)
    }
  };

  useEffect(()=>{
    if(isAuthenticated)  fetchWallet()
  },[])
 

  const [copied, setCopied] = useState(false);
  const referralCode = isAuthenticated ? auth.user.referralCode : "Salmanxyz";


  const handleRedeem = () =>{
    isAuthenticated ? handleShow("transaction") : toast.info("Please login to continue.",{autoClose:2000,toastId:"login"})
  }

  const handleCopyClick = () => {
    navigator.clipboard.writeText(referralCode).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); 
    }).catch((err) => {
      console.error('Failed to copy: ', err);
    });
  };

 
  return (
    <>
    <TransactionModal show={modalType === "transaction"} fetchWallet={fetchWallet} walletId={wallet._id} handleClose={handleClose} handleShow={handleShow}/>
        <div className="referral-box">
          <ul>
            <li>
              <h3>My Earnings</h3>
              <h2>Rs. {isAuthenticated ?  wallet.balance ? wallet.balance: 0  : 2500 }</h2>
            </li>
            <li>
              <h3>My Referrals</h3>
              <h2>{isAuthenticated ? auth.user.totalReferrals : "01"}</h2>
            </li>
          </ul>
          <p className="copy">
            Referral Code: 
             <a>
              {referralCode} 
              <i 
            className={`fa-regular ${copied ? 'fa-check' : 'fa-copy'}`} 
            onClick={handleCopyClick}
            style={{ cursor: 'pointer', transition: 'color 0.3s' }}></i>
            </a>
          </p>

          {
          
          <Link   onClick={() => handleRedeem()} className="btn-red" >
            Redeem Now
          </Link>

          }
        </div>
    </>
  );
};

export default ReferralBox;
