import "bootstrap/dist/css/bootstrap.min.css";
import CountUp from 'react-countup';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Banner from "../component/banner";
import AboutImg from "../assets/images/about-img.png";
import AboutImgg from "../assets/images/about-2.png";
import Icon1 from "../assets/images/fun-facts-1.png";
import Icon2 from "../assets/images/fun-facts-2.png";
import Icon3 from "../assets/images/fun-facts-3.png";
import Icon4 from "../assets/images/fun-facts-4.png";

const AboutUs = () => {
  const dynamicText = "About Us";
  return (
    <>
    <Banner title={dynamicText} additionalClass="justify-content-center text-center"/>
    <section className="about-sec">
      <Container>
        <Row className="justify-content-around align-items-center gy-4">
          <Col sm={12} md={5} lg={5}>
            <div className="title">
              <h2>Who We Are</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur
                    sint occaecat cupidatat non proident, sunt in culpa qui.</p>
              <ul>
                <li>Lorem ipsum dolor</li>
                <li>Lorem ipsum dolor</li>
                <li>Lorem ipsum dolor</li>
              </ul>
              <div className="btn-ab">
                <a href="#">Get A Quote</a>
              </div>
            </div>
          </Col>
          <Col sm={12} md={5} lg={5}>
            <img className="img-fluid" src={AboutImg} />
          </Col>
        </Row>
      </Container>
    </section>
    <section className="islamic-counter-sec">
      <div className="container">
        <Row className="fun-facts gy-4">
          <Col sm={12} md={3} lg={3}>
            <div className="islamic-mosque">
              <div className="count-style">
                <h2 className="ms-animated">
                  <CountUp end={62} duration={3} />
                  <sup>+</sup>
                </h2>
              </div>
              <h5>Islamic Mosque</h5>
              <img src={Icon1} alt="Islamic Mosque Icon" />
            </div>
          </Col>
          <Col sm={12} md={3} lg={3}>
            <div className="islamic-mosque">
              <div className="count-style">
                <h2 className="ms-animated">
                  <CountUp end={14} duration={5} separator="," />
                  <sup>K</sup>
                </h2>
              </div>
              <h5>Learning Students</h5>
              <img src={Icon2} alt="Learning Students Icon" />
            </div>
          </Col>
          <Col sm={12} md={3} lg={3}>
            <div className="islamic-mosque">
              <div className="count-style">
                <h2 className="ms-animated">
                  <CountUp end={317} duration={3} />
                  <sup>+</sup>
                </h2>
              </div>
              <h5>Inspirational Courses</h5>
              <img src={Icon3} alt="Inspirational Courses Icon" />
            </div>
          </Col>
          <Col sm={12} md={3} lg={3}>
            <div className="islamic-mosque">
              <div className="count-style">
                <h2 className="ms-animated">
                  <CountUp end={26} duration={3} />
                  <sup>+</sup>
                </h2>
              </div>
              <h5>Islamic Scholars</h5>
              <img src={Icon4} alt="Islamic Scholars Icon" />
            </div>
          </Col>
        </Row>
      </div>
  </section>
  <section className="listen-holy">
      <div className="container">
          <div className="row gy-4">
              <div className="col-lg-6">
                  <div className="heading two">
                      <p>Listen Holy Quran</p>
                      <h2>Recite & Listen the Holy Quran Online</h2>
                      <h6>Duis aute irure dolor in reprehenit in voluptate velit esse cillum dolo re eu fugiat nulla pariatur.</h6>
                      <div className="don-btn">
                      <a href="#" >Donate Now</a>
                      </div>
                  </div>
              </div>
              <div className="offset-lg-1 col-lg-5">
                  <div className="support-us">
                      <h4>Support us, we need your help.</h4>
                      {/* <div className="progressbar">
                          <div className="circle" data-percent="85">
                              <div>85%</div>
                          </div>
                      </div> */}
                      <h6>$71,000</h6>
                      <span>Donation Collected</span>
                      <div className="don-btn">
                      <a href="#" >Donate Now</a>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
    <section className="about-sec about-btm">
      <Container>
        <Row className="justify-content-around align-items-center gy-4">
          <Col sm={12} md={5} lg={5}>
            <div className="title">
              <h2>Who We Are</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur
                    sint occaecat cupidatat non proident, sunt in culpa qui.</p>
              <ul>
                <li>Lorem ipsum dolor</li>
                <li>Lorem ipsum dolor</li>
                <li>Lorem ipsum dolor</li>
              </ul>
              <div className="btn-ab">
                <a href="#">Get A Quote</a>
              </div>
            </div>
          </Col>
          <Col sm={12} md={5} lg={5}>
            <img className="img-fluid" src={AboutImgg} />
          </Col>
        </Row>
      </Container>
    </section>
  </>
  );
};

export default AboutUs;
