import React, {  useState } from "react";
import { Modal } from "react-bootstrap";
import logo from "../../assets/images/logo.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";

const TransactionModal = ({ show, handleClose, walletId , fetchWallet }) => {

  const auth = useAuthUser();
  const [amount, setAmount] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [accountTitle, setAccountTitle] = useState('');
  const [bankName, setBankName] = useState('');
  const [disabled, setDisabled] = useState(false);
 

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true)
    const signUpToast =  toast.loading("Creating Account...",{
      toastId:"singup_toast",
      autoClose:false
    })

    if (!amount || !accountNumber || !accountTitle || !bankName) {
      toast.update(signUpToast,{
        render:"Please fill in all required fields.",
        type:"info",
        autoClose:2000,
        isLoading:false
      })
      return;
    }

    try {
      const response = await fetch('https://islamic-world-1.onrender.com/api/users/create-transection', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': auth.user.token,
        },
        body: JSON.stringify({ userId: auth.user._id, amount, walletId : walletId,type:"credit", accountNumber, accountTitle, bankName }),
      });

      const data = await response.json();

      if (response.ok) {

         // Navigate to email verification step
         fetchWallet();
         handleClose("transaction")
         toast.update(signUpToast,{
          render:'Transaction Successfull',
          type:"success",
          autoClose:2000,
          isLoading:false
        })

      

      } else {
        toast.update(signUpToast,{
          render:data.message,
          type:"error",
          autoClose:2000,
          isLoading:false
        })
      }
    } catch (err) {
      toast.update(signUpToast,{
        render:err.message,
        type:"error",
        autoClose:2000,
        isLoading:false
      })
    }finally{
      setDisabled(false)
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="md"
      className="modal-login-sec modal-create-account"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Row className="justify-content-center">
          <Col sm={12} md={9} lg={9}>
            <div className="logo">
              <img className="img-fluid" src={logo} alt="Logo" />
            </div>
            <div className="title">
              <h2>Account Details</h2>
            </div>
            <form onSubmit={handleSubmit}>
              <Row className="gy-4">
                <Col sm={12}>
                  <div className="txt-box">
                    <input
                      placeholder="Account Title"
                      value={accountTitle}
                      onChange={(e) => setAccountTitle(e.target.value)}
                      required
                    />
                  </div>
                </Col>
                <Col sm={12}>
                  <div className="txt-box">
                    <input
                    type="number"
                      placeholder="Account Number"
                      value={accountNumber}
                      onChange={(e) => setAccountNumber(e.target.value)}
                    />
                  </div>
                </Col>
                <Col sm={12}>
                  <div className="txt-box">
                    <input
                      placeholder="Bank Name"
                      type="text"
                      value={bankName}
                      onChange={(e) => setBankName(e.target.value)}
                      required
                    />
                  </div>
                </Col>
                <Col sm={12}>
                  <div className="txt-box">
                    <input
                      placeholder="Amount"
                      type="number"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      required
                    />
                  </div>
                </Col>
                  
                <Col sm={12}>
                  <div className="btn-sub">
                    <button type="submit" disabled={disabled}>
                      Submit
                    </button>
                  </div>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default TransactionModal;
