import React from "react";
import { Modal } from "react-bootstrap";
import logo from "../../assets/images/logo.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const SuccessModal = ({ show, handleClose, handleShow }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="md"
      className="modal-login-sec modal-success"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Row className="justify-content-center">
          <Col sm={12} md={9} lg={9}>
            <div className="logo">
              <img className="img-fluid" src={logo} alt="Logo" />
            </div>
            <div className="title">
              <h2> <span>Congratulations!</span>
              </h2>
              <p>You have been successfully subscribed to Islamic World. You can now access all the content available and earn through our referral program.</p>
            </div>
            <Row className="gy-4">
              <Col sm={12}>
                <div className="btn-sub">
                  <button onClick={() => handleClose('success')}>Continue </button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default SuccessModal;
